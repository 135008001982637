
const GOOGLE_MAX_PASSWORD_LENGTH = 100
const AZURE_AD_MAX_PASSWORD_LENGTH = 256
const AD_MAX_PASSWORD_LENGTH = 256

/**
 * Rules to be used in accompany to `v-text-field` attribute `rules` or similar validation checks.
 * @typedef {Object} ValidationRule
 * @property {string} errorMessage - Error message to show if validation does not pass.
 * @property {function} func - Validator function. Returns `true` or `string`.
 * @see https://vuetifyjs.com/en/components/text-fields/#validation
 */

/**
 * Check if string has at least one lowercase alphabet.
 *
 * Used for password complexity checking.
 *
 * `errorMessage` is optional and not used in `complexityBuilder`.
 * `func` returns `true` if at least one lowercase alphabet is found in `value`.
 * @type ValidationRule
 * @see complexityBuilder
 */
const testLowerCase = {
  errorMessage: '',
  func: (value) => /[a-z]/.test(value)
}

/**
 * Check if string has at least one uppercase alphabet.
 * @type ValidationRule
 * @see testLowerCase
 */
const testUpperCase = {
  errorMessage: '',
  func: (value) => /[A-Z]/.test(value)
}

/**
 * Check if string has at least one numeric character.
 * @see testLowerCase
 */
const testNumber = {
  errorMessage: '',
  func: (value) => /[0-9]/.test(value)
}

/**
 * Check if string has at least one non-alphanumeric character.
 *
 * @type ValidationRule
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Cheatsheet
 * @see testLowerCase
 */
const testSymbol = {
  errorMessage: '',
  func: (value) => /(\W|_)/.test(value)
}

/**
 * Check that string has only lower or upper case alphabets or numbers.
 *
 * Used in `invalidCharactersBuilder` which uses the `errorMessage` property.
 * `\W` would also match underscore `_`.
 * @type ValidationRule
 * @see invalidCharactersBuilder
 */
const testNonAlphanumeric = {
  errorMessage: 'Password can only contain upper and lower case alphabets and numbers.',
  func: (value) => {
    return /[^a-zA-Z0-9]/.test(value)
  }
}

/**
 * Builder function for checking password length.
 * @param {Object} $t - `vue-i18n` object from Vue element.
 * @param {Number} minLength - Integer value for minimum password length.
 * @returns {function} - Validator function. Returns `true` if validation passes,
 *   else error message as a `string`.
 */
const minPasswordLengthBuilder = ($t, minLength) => (value) => {
  return value.length >= minLength || $t('Password must be at least {0} characters', [ minLength ])
}

/**
 * @see minPasswordLengthBuilder
 */
const maxPasswordLengthBuilder = ($t, maxLength) => (value) => {
  // console.log({ _: 'max', maxLength, value })
  const _maxLength = maxLength ?? GOOGLE_MAX_PASSWORD_LENGTH
  if (value?.length > _maxLength) {
    return $t('Maximum password length is {0} characters', [ _maxLength ])
  }
  return true
}

/**
 * Builder for a function that checks if entered password passes the required complexity.
 *
 * @param {Object} $t - `vue-i18n` object from Vue element.
 * @param {Number} requiredComplexity - How many `validationRules` functions must return true
 *   to match or exceed required complexity. Short example is for password to require at least one
 *   lowercase, upppercase and number character.
 * @param {Array.<ValidationRule>} validationRules - Array of `ValidationRule` objects.
 * @returns {function} - Validator function. Returns `true` if validation passes,
 *   else error message as a `string`.
 * @see DefaultRules
 * @see ValidationRule
 * @see https://vuetifyjs.com/en/components/text-fields/#validation
 */
const complexityBuilder = ($t, requiredComplexity, validationRules) => (value) => {
  // Azure AD Strong: 3 out of 4: lowercase, uppercase, numbers, or symbols.
  // const requiredComplexity = 3
  // const complexityFunctions = [testLowerCase, testUpperCase, testNumber, testSymbol]
  const complexity = validationRules.filter((validationRule) => validationRule.func(value)).length
  return complexity >= requiredComplexity || $t('Password requires 3 out of 4 of lowercase, uppercase, numbers, or symbols.')
}

/**
 * Builder for a function that checks that entered password does not contain invalid characters.
 * @see complexityBuilder
 */
const invalidCharactersBuilder = ($t, validationRules) => (value) => {
  const invalidMatch = validationRules.find((validationRule) => validationRule.func(value))
  if (invalidMatch) {
    // TODO: Display allowed characters somehow?
    return $t(invalidMatch.errorMessage)
  }
  return true
}

/**
 * Parent class for password validation rules.
 *
 * Child class must implement the methods (rules) `noInvalidCharacters`,
 * `complexity`, `min` and `max`.
 *
 * @type ValidationRules
 * @see https://vuetifyjs.com/en/components/text-fields/#validation
 */
class BaseValidationRules {
  constructor () {
    // Initialise required methods.
    this.noInvalidCharacters = null
    this.complexity = null
    this.min = null
    this.max = null
  }
  /**
   * Return all validation rules to be used in `rules` binding for example in `v-text-field`
   *
   * @returns {Array.<function>} - Array
   */
  getRules () {
    const validationFunctions = [
      this.noInvalidCharacters,
      this.complexity,
      this.min,
      this.max
    ]
    for (const func of validationFunctions) {
      if (typeof func !== 'function') {
        console.error({ _: 'Missing validation function', this: this, validationFunctions })
        throw Error('Missing validation function')
      }
    }
    return validationFunctions
  }
}

class DefaultRules extends BaseValidationRules {
  static rulesName = 'DEFAULT_RULES'
  /**
   * Create a ValidationRules object.
   *
   * @param {Object} $t - vue-i18n object that is used for translating error messages.
   *   **NOTICE**: the `this.$t` in Vue component must be passed by using `this.$t.bind(this)`, eg:
   *
   *     export default {
   *       name: 'ValidatorTesting',
   *       computed: {
   *       rules () {
   *         return defaultRules(this.$t.bind(this))
   *       }
   *     }
   * @see https://kazupon.github.io/vue-i18n/api/#extension-of-vue
   * @see https://github.com/kazupon/vue-i18n/issues/259
   */
  constructor ($t) {
    super()
    this.rulesName = 'DEFAULT_RULES'
    this.min = minPasswordLengthBuilder($t, 8)
    this.max = maxPasswordLengthBuilder($t)
    this.complexity = complexityBuilder($t, 3, [testLowerCase, testUpperCase, testNumber, testSymbol])
    this.noInvalidCharacters = () => true
  }
}

class TwelveNoSpecialRules extends BaseValidationRules {
  static rulesName = 'TWELVE_NO_SPECIAL_RULES'
  /**
   * Create a ValidationRules object
   * @see DefaultRules
   */
  constructor ($t) {
    super()
    this.min = minPasswordLengthBuilder($t, 12)
    this.max = maxPasswordLengthBuilder($t, Math.min(
      GOOGLE_MAX_PASSWORD_LENGTH,
      AZURE_AD_MAX_PASSWORD_LENGTH,
      AD_MAX_PASSWORD_LENGTH
    ))
    this.complexity = complexityBuilder($t, 3, [testLowerCase, testUpperCase, testNumber])
    this.noInvalidCharacters = invalidCharactersBuilder($t, [testNonAlphanumeric])
  }
}

function getValidationRulesClassByName (rulesName) {
  const rulesClasses = [DefaultRules, TwelveNoSpecialRules]
  const ValidatorRulesClass = rulesClasses.find((rules) => rules.rulesName === rulesName)
  return ValidatorRulesClass ?? DefaultRules
}

export { DefaultRules, TwelveNoSpecialRules, getValidationRulesClassByName }
