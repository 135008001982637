<template>
  <v-container max-width="960px">
    <v-card class="pa-8">
      <v-card-title>
        <h1>{{ $t('Reset password') }}</h1>
      </v-card-title>
      <v-card-text v-if="loading">
        <p v-show="!verifying">{{ $t('Resetting password') }}</p>
        <p v-show="verifying">{{ $t('Verifying reset request') }}</p>
        <v-progress-linear v-show="loading" v-model="loadingProgress" :indeterminate="verifying"></v-progress-linear>
      </v-card-text>
      <v-card-text v-else-if="invalidTicket">
        <v-alert type="error">{{ $t('Invalid ticket') }}</v-alert>
      </v-card-text>
      <v-card-text v-else>
        {{ $t('Reset password') }}
      </v-card-text>
      <v-form v-model="valid" v-show="!loading && !invalidTicket && !successSubmit">
        <v-container>
          <input type="text" name="email" value="email-address-hidden" autocomplete="username email" style="display: none;">
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="newPassword" autocomplete="new-password" type="password" :rules="rules" :label="$t('New password')" :placeholder="$t('Password')"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="confirmPassword" autocomplete="new-password" type="password" :rules="[match]" :label="$t('Confirm new password')" :placeholder="$t('Password')"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn :disabled="!reallyValid" @click="submit({ newPassword })">{{ $t('Submit') }}</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-alert v-show="errorInvalidContactInformation" type="error">{{ $t('Invalid contact information') }}</v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-alert v-show="successSubmit" type="success">{{ $t('Password changed successfully') }}</v-alert>
      <v-alert v-show="lastError" type="error">{{ lastError }}</v-alert>
    </v-card>
  </v-container>
</template>

<script>
import { getJson } from '@/helpers'
import { getValidationRulesClassByName } from '@/validators'

const API = process.env.VUE_APP_API_BASE_URL || ''

const ERROR_STATUS_MESSAGES = {
  'googleResetError': 'Error while updating password for G Suite account',
  'azureResetError': 'Error while updating password for Office 365 account',
  'errorDuringReset': 'Error while updating passwords',
  'userNotFound': 'User not found',
  'missingPassword': 'Password is missing',
  'resourceNotFound': 'Resource not found'
}

export default {
  name: 'NomenPasswordResetPage',
  computed: {
    rules () {
      return this.validationRules?.getRules() ?? []
    },
    reallyValid () {
      return this.valid && this.newPassword.length && this.confirmPassword.length
    }
  },
  data () {
    return {
      newPassword: '',
      confirmPassword: '',
      verifying: true,
      loading: true,
      invalidTicket: false,
      errorInvalidContactInformation: false,
      successSubmit: false,
      lastError: null,
      valid: false,
      ticketId: null,
      uid: null,
      loadingProgress: 0,
      loadingAnimation: {
        startTime: null,
        endTime: null,
        duration: 10000, // milliseconds
        easeFunction: function (t) { return (--t) * t * t + 1 }
      },
      validationRules: null
    }
  },
  methods: {
    drawLoadingBar ({ startTime, endTime, duration, easeFunction }) {
      if (!this.loading) {
        return
      }
      const time = (new Date()).getTime()
      if (time >= endTime) {
        return
      }
      const t = (time - startTime) / duration
      this.loadingProgress = Math.round(100 * easeFunction(t)) // Percentage
      window.requestAnimationFrame(async () => {
        await new Promise((resolve) => setTimeout(resolve, 120, true))
        this.drawLoadingBar({ startTime, endTime, duration, easeFunction })
      })
    },
    startLoading ({ duration, easeFunction }) {
      this.loadingProgress = 0
      this.loading = true
      const animation = { ...this.animation }
      animation.easeFunction = easeFunction
      animation.duration = duration
      animation.startTime = (new Date()).getTime()
      animation.endTime = animation.startTime + animation.duration
      // console.debug({ animation })
      this.drawLoadingBar(animation)
    },
    submit ({ newPassword }) {
      // this.loading = true
      this.startLoading({ ...this.loadingAnimation })
      this.errorInvalidContactInformation = false
      this.successSubmit = false
      this.lastError = null
      const fetchOptions = {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({ uid: this.uid, newPassword })
      }
      fetch(`${API}/pwd-api/v1/reset/${this.ticketId}`, fetchOptions)
        .then(getJson)
        .then((resp) => {
          if (resp.status === 'ok') {
            this.successSubmit = true
            this.lastError = null
          } else {
            const errorMessage = ERROR_STATUS_MESSAGES[resp.status]
            if (errorMessage) {
              this.lastError = this.$t(errorMessage)
            } else {
              this.lastError = resp.status
            }
            return Promise.reject(new Error(resp.status))
          }
        })
        .catch((error) => {
          if (error.message) {
            this.lastError = error.message
            return
          }
          this.lastError = 'Internal error'
          console.error({ error }) // eslint-disable-line no-console
        })
        .finally(() => {
          this.loading = false
        })
    },
    setValidationRules ({ validationRules }) {
      const ValidatorRules = getValidationRulesClassByName(validationRules)
      this.validationRules = new ValidatorRules(this.$t.bind(this))
    },
    match (value) {
      return value === this.newPassword || this.$t('Password doesn\'t match')
    }
  },
  mounted () {
    this.verifying = true
    this.ticketId = this.$route.params.ticketId
    fetch(`${API}/pwd-api/v1/verify/${this.ticketId}`)
      .then((response) => {
        if (response.status === 200) {
          return response.json()
        } else if (response.status === 404) {
          this.errorInvalidContactInformation = true
          return Promise.reject(new Error('NOT_FOUND'))
        }
        // console.debug({ response })
        return Promise.reject(new Error(response))
      })
      .then((json) => {
        // console.debug({ json })
        this.invalidTicket = false
        this.uid = json.uid
        this.setValidationRules(json)
      })
      .catch((error) => {
        this.invalidTicket = true
        if (error.message && error.message === 'NOT_FOUND') {
          return
        }
        console.error({ error }) // eslint-disable-line no-console
      })
      .finally(() => {
        this.loading = false
        this.verifying = false
      })
  }
}
</script>
