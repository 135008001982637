import Vue from 'vue'
import VueRouter from 'vue-router'
import NomenRecoveryPage from '../views/NomenRecoveryPage.vue'
import NomenTicketValidationPage from '../views/NomenTicketValidationPage.vue'
import ValidatorTesting from '../views/ValidatorTesting.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'RecoveryPage',
    component: NomenRecoveryPage
  },
  {
    path: '/t/:ticketId',
    name: 'TicketValidationPage',
    component: NomenTicketValidationPage
  }
]

const developmentRoutes = [
  {
    path: '/validator-testing',
    name: 'ValidatorTesting',
    component: ValidatorTesting
  }
]

if (process.env.NODE_ENV === 'development') {
  developmentRoutes.forEach((route) => {
    routes.push(route)
  })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
