<template>
  <v-app id="nomen-password-portal" light>
    <v-main :class="{ background: true, 'background--dark': darkTheme }">
      <div class="ui-layout-pad">
        <h1 class="pa-4 title">Nomen</h1>
        <div class="pl-4">
        <v-icon dark class="pr-4">mdi-translate</v-icon>
        <v-btn :text="$i18n.locale !== 'fi'" :dark="$i18n.locale !== 'fi'" @click="setLocale('fi')">FI</v-btn>
        <v-btn :text="$i18n.locale !== 'en'" :dark="$i18n.locale !== 'en'" @click="setLocale('en')">EN</v-btn>
        </div>
      </div>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'NomenPasswordPortal',
  computed: {
    darkTheme () {
      return this.$vuetify.theme.dark
    }
  },
  data: () => ({
    //
  }),
  methods: {
    setLocale (locale) {
      this.$i18n.locale = locale
    }
  }
}
</script>

<style scoped>
#nomen-password-portal {
  background-color: var(--v-background-base);
}
.ui-layout-pad {
  /* Add space between top of the page and the content. In Nomen layout the tab bar is also located in this space.
    Minust 12px comes from the v-content default padding.
  */
  height: calc(128px - 12px);
  width: 100%;
}
.title {
  font-size: 20px;
  color: white;
}
.background {
  background-image: url('~@/assets/Nomen-header-224-skew.jpg');
}
</style>
