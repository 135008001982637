<template>
  <v-container max-width="960px">
    <v-card class="pa-8">
      <v-card-title>
        <h1>Validator Testing</h1>
      </v-card-title>
      <v-form v-model="valid">
        <v-container>
          <input type="text" name="email" value="email-address-hidden" autocomplete="username email" style="display: none;">
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="newPassword" autocomplete="new-password" type="password" :rules="rules" :label="$t('New password')" :placeholder="$t('Password')"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="confirmPassword" autocomplete="new-password" type="password" :rules="[match]" :label="$t('Confirm new password')" :placeholder="$t('Password')"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn :disabled="!reallyValid" @click="submit({ newPassword })">{{ $t('Submit') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
// import { defaultRules } from '@/validators'
import { TwelveNoSpecialRules } from '@/validators'

export default {
  name: 'ValidatorTesting',
  computed: {
    rules () {
      return this.validationRules.getRules()
    },
    reallyValid () {
      return this.valid && this.newPassword.length && this.confirmPassword.length
    }
  },
  data () {
    return {
      newPassword: '',
      confirmPassword: '',
      valid: false,
      validationRules: new TwelveNoSpecialRules(this.$t.bind(this))
    }
  },
  methods: {
    match (value) {
      return value === this.newPassword || this.$t('Password doesn\'t match')
    }
  }
}
</script>
