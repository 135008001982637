<template>
  <v-container max-width="960px">
    <v-card class="pa-8">
      <v-card-title>
        <h1>{{ $t('Reset password') }}</h1>
      </v-card-title>
      <v-card-text>
        {{ $t('RESET_PASSWORD_INSTRUCTIONS') }}
      </v-card-text>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="primaryEmail" :rules="[rules.email]" :label="$t('Primary email address')" :placeholder="$t('Email')"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="recoveryEmail" :rules="[rules.email]" :label="$t('Recovery email address')" :placeholder="$t('Email')"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn :disabled="!valid" @click="submit({ primaryEmail, recoveryEmail })">{{ $t('Submit') }}</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div v-show="loading">{{ $t('Checking contact information') }}</div>
              <v-progress-linear v-show="loading" v-model="loadingProgress"></v-progress-linear>
              <v-alert v-show="lastError" type="error">{{ lastError }}</v-alert>
              <v-alert v-show="successSubmit" type="success">{{ $t('Password reset link sent') }}</v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { getJson } from '@/helpers'

const API = process.env.VUE_APP_API_BASE_URL || ''
const ERROR_STATUS_MESSAGES = {
  'badTicketRequest': 'Reset request has been rejected. Try again in fifteen minutes.',
  'userNotFound': 'User or recovery email not found',
  'invalidRecoveryEmail': 'Recovery email can not be in the same domain as primary account'
}

export default {
  name: 'NomenRecoveryPage',
  data: () => ({
    primaryEmail: '',
    recoveryEmail: '',
    loading: false,
    loadingProgress: 0,
    // errorInvalidContactInformation: false,
    lastError: null,
    successSubmit: false,
    valid: false,
    loadingAnimation: {
      startTime: null,
      endTime: null,
      duration: 20000, // milliseconds
      easeFunction: function (t) { return (--t) * t * t + 1 }
    }
  }),
  computed: {
    rules: function () {
      return {
        email: (value) => {
          const pattern = /@[^.]+\.[^.]{2,}/
          return pattern.test(value) || this.$t('Invalid email address')
        }
      }
    }
  },
  methods: {
    drawLoadingBar ({ startTime, endTime, duration, easeFunction }) {
      // console.debug({ startTime })
      if (!this.loading) {
        // Loading/fetching is done.
        // console.debug('Stopping progress animation')
        return
      }
      const time = (new Date()).getTime()
      if (time >= endTime) {
        return
      }
      const t = (time - startTime) / duration
      this.loadingProgress = Math.round(100 * easeFunction(t)) // Percentage
      // console.debug({ t, lp: this.loadingProgress })
      window.requestAnimationFrame(async () => {
        await new Promise((resolve) => setTimeout(resolve, 40, true))
        this.drawLoadingBar({ startTime, endTime, duration, easeFunction })
      })
      // this.$nextTick(() => {
      //   this.drawLoadingBar({ startTime, endTime, duration, easeFunction })
      // })
    },
    startLoading ({ duration, easeFunction }) {
      this.loadingProgress = 0
      this.loading = true
      const animation = { ...this.animation }
      animation.easeFunction = easeFunction
      animation.duration = duration
      animation.startTime = (new Date()).getTime()
      animation.endTime = animation.startTime + animation.duration
      // console.debug({ animation })
      this.drawLoadingBar(animation)
    },
    submit ({ primaryEmail, recoveryEmail }) {
      this.startLoading({ ...this.loadingAnimation })
      this.errorInvalidContactInformation = false
      this.successSubmit = false
      this.lastError = null
      fetch(`${API}/pwd-api/v1/recover/email/${primaryEmail}/recovery/${recoveryEmail}`)
        .then(getJson)
        .then((resp) => {
          if (resp.status === 'ok') {
            this.successSubmit = true
            this.lastError = null
            return Promise.resolve(resp)
          }
          return Promise.reject(resp)
        })
        .catch((errorResponse) => {
          const status = errorResponse && errorResponse.status
          if (!status) {
            this.lastError = 'Internal error'
            console.error({ errorResponse }) // eslint-disable-line no-console
            return errorResponse
          }
          const message = ERROR_STATUS_MESSAGES[status]
          if (message) {
            this.lastError = this.$t(message)
          } else {
            this.lastError = this.$t(status)
          }
          return errorResponse
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
